/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AnswerTExamDto } from '../../models/answer-t-exam-dto';
import { TExamQuestionType } from '../../models/t-exam-question-type';
import { UpdateTExamQuestionCommandResult } from '../../models/update-t-exam-question-command-result';
import { UpdateTExamQuestionDto } from '../../models/update-t-exam-question-dto';

export interface ApiTExamUpdateTExamQuestionPatch$Params {
      body?: {
'Question.Id'?: number;
'Question.AudioFile'?: Blob;
'Question.AudioPlayTimes'?: number;
'Question.Type'?: TExamQuestionType;
'Question.Sort'?: number;
'Question.Title'?: string;
'Question.QuestionImage'?: Blob;
'Question.TExamId'?: number;
'Question.Answers'?: Array<AnswerTExamDto>;
'SubQuestions'?: Array<UpdateTExamQuestionDto>;
}
}

export function apiTExamUpdateTExamQuestionPatch(http: HttpClient, rootUrl: string, params?: ApiTExamUpdateTExamQuestionPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamQuestionCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiTExamUpdateTExamQuestionPatch.PATH, 'patch');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateTExamQuestionCommandResult>;
    })
  );
}

apiTExamUpdateTExamQuestionPatch.PATH = '/api/TExam/UpdateTExamQuestion';
