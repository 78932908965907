import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { NzImageModule } from 'ng-zorro-antd/image';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { Gender, TalentStudent } from 'src/app/core/backend/models';
import { TalentedStudentsService } from 'src/app/core/backend/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mg-talented-students-board',
  standalone: true,
  imports: [CommonModule, CarouselModule, NzImageModule],
  templateUrl: './talented-students-board.component.html',
  styleUrls: ['./talented-students-board.component.scss']
})
export class TalentedStudentsBoardComponent {
  constructor(
    private TSService: TalentedStudentsService
  ) {
    this.getAll()
  }

  @Output() hasDataFired = new EventEmitter<TalentStudent[]>();
  genderEnum = Gender;
  domain = environment.api;
  gettingData = false;
  allStudents: TalentStudent[] | undefined | null = [];
  coursesCarousel: OwlOptions = {
    loop: true,
    items: 4,
    mouseDrag: true,
    touchDrag: true,
    autoWidth: false,
    pullDrag: true,
    freeDrag: true,
    dots: true,
    rtl: true,
    margin: 20,
    navSpeed: 300,
    autoplay: true,
    autoplayHoverPause: true,
    animateIn: false,
    animateOut: false,
    autoplayTimeout: 3000,
    autoplaySpeed: 1500,
    autoplayMouseleaveTimeout: 0,
    responsive: {
      0: {
        items: 1,
        dots: false
      },
      400: {
        items: 2,
        dots: false
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  }
  getAll() {
    this.gettingData = true;
    this.TSService
      .apiTalentedStudentsGetTalentedStudentsPost({
        body: {}
      })
      .subscribe({
        next: (res) => {
          console.log(res);
          this.allStudents = res.students?.items;
          if (this.allStudents?.length) {
            this.hasDataFired.emit(this.allStudents);
          }
          this.gettingData = false;
        },
        error: (err) => {
          console.log(err);
          this.gettingData = false;
        },
      });
  }
}
