/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiQyasExamCreateExamPost } from '../fn/qyas-exam/api-qyas-exam-create-exam-post';
import { ApiQyasExamCreateExamPost$Params } from '../fn/qyas-exam/api-qyas-exam-create-exam-post';
import { apiQyasExamCreateExamPost$Plain } from '../fn/qyas-exam/api-qyas-exam-create-exam-post-plain';
import { ApiQyasExamCreateExamPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-create-exam-post-plain';
import { apiQyasExamDeleteExamDelete } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete';
import { ApiQyasExamDeleteExamDelete$Params } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete';
import { apiQyasExamDeleteExamDelete$Plain } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete-plain';
import { ApiQyasExamDeleteExamDelete$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-delete-exam-delete-plain';
import { apiQyasExamGetExamPost } from '../fn/qyas-exam/api-qyas-exam-get-exam-post';
import { ApiQyasExamGetExamPost$Params } from '../fn/qyas-exam/api-qyas-exam-get-exam-post';
import { apiQyasExamGetExamPost$Plain } from '../fn/qyas-exam/api-qyas-exam-get-exam-post-plain';
import { ApiQyasExamGetExamPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-get-exam-post-plain';
import { apiQyasExamGetExamsPost } from '../fn/qyas-exam/api-qyas-exam-get-exams-post';
import { ApiQyasExamGetExamsPost$Params } from '../fn/qyas-exam/api-qyas-exam-get-exams-post';
import { apiQyasExamGetExamsPost$Plain } from '../fn/qyas-exam/api-qyas-exam-get-exams-post-plain';
import { ApiQyasExamGetExamsPost$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-get-exams-post-plain';
import { apiQyasExamUpdateExamPatch } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch';
import { ApiQyasExamUpdateExamPatch$Params } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch';
import { apiQyasExamUpdateExamPatch$Plain } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch-plain';
import { ApiQyasExamUpdateExamPatch$Plain$Params } from '../fn/qyas-exam/api-qyas-exam-update-exam-patch-plain';
import { CreateTExamCommandResult } from '../models/create-t-exam-command-result';
import { DeleteTExamCommandResult } from '../models/delete-t-exam-command-result';
import { GetExamQueryResult } from '../models/get-exam-query-result';
import { GetExamsQueryResult } from '../models/get-exams-query-result';
import { UpdateTExamCommandResult } from '../models/update-t-exam-command-result';

@Injectable({ providedIn: 'root' })
export class QyasExamService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiQyasExamGetExamPost()` */
  static readonly ApiQyasExamGetExamPostPath = '/api/QyasExam/GetExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost$Plain$Response(params?: ApiQyasExamGetExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamQueryResult>> {
    return apiQyasExamGetExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost$Plain(params?: ApiQyasExamGetExamPost$Plain$Params, context?: HttpContext): Observable<GetExamQueryResult> {
    return this.apiQyasExamGetExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamQueryResult>): GetExamQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost$Response(params?: ApiQyasExamGetExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamQueryResult>> {
    return apiQyasExamGetExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamPost(params?: ApiQyasExamGetExamPost$Params, context?: HttpContext): Observable<GetExamQueryResult> {
    return this.apiQyasExamGetExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamQueryResult>): GetExamQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamGetExamsPost()` */
  static readonly ApiQyasExamGetExamsPostPath = '/api/QyasExam/GetExams';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost$Plain$Response(params?: ApiQyasExamGetExamsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamsQueryResult>> {
    return apiQyasExamGetExamsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost$Plain(params?: ApiQyasExamGetExamsPost$Plain$Params, context?: HttpContext): Observable<GetExamsQueryResult> {
    return this.apiQyasExamGetExamsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamsQueryResult>): GetExamsQueryResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamGetExamsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost$Response(params?: ApiQyasExamGetExamsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<GetExamsQueryResult>> {
    return apiQyasExamGetExamsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamGetExamsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamGetExamsPost(params?: ApiQyasExamGetExamsPost$Params, context?: HttpContext): Observable<GetExamsQueryResult> {
    return this.apiQyasExamGetExamsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetExamsQueryResult>): GetExamsQueryResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamCreateExamPost()` */
  static readonly ApiQyasExamCreateExamPostPath = '/api/QyasExam/CreateExam';

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamCreateExamPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost$Plain$Response(params?: ApiQyasExamCreateExamPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamCommandResult>> {
    return apiQyasExamCreateExamPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamCreateExamPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost$Plain(params?: ApiQyasExamCreateExamPost$Plain$Params, context?: HttpContext): Observable<CreateTExamCommandResult> {
    return this.apiQyasExamCreateExamPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamCommandResult>): CreateTExamCommandResult => r.body)
    );
  }

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamCreateExamPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost$Response(params?: ApiQyasExamCreateExamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamCommandResult>> {
    return apiQyasExamCreateExamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Title:اختبار تيست
   * Description:وصف الامتحان الخطير
   * DurationInMinutes:1
   * StartDate:2024-09-26T00:00:00Z
   * EndDate:2024-09-26T11:59:00Z
   * TeacherId:46F84B1A-24D9-4BEE-93DD-531D06C6EBB5
   * CourseTeacherIDs:7D29C67B-8064-4BDE-7A2E-08DC4CB12337
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamCreateExamPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamCreateExamPost(params?: ApiQyasExamCreateExamPost$Params, context?: HttpContext): Observable<CreateTExamCommandResult> {
    return this.apiQyasExamCreateExamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreateTExamCommandResult>): CreateTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamUpdateExamPatch()` */
  static readonly ApiQyasExamUpdateExamPatchPath = '/api/QyasExam/UpdateExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamUpdateExamPatch$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch$Plain$Response(params?: ApiQyasExamUpdateExamPatch$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamCommandResult>> {
    return apiQyasExamUpdateExamPatch$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamUpdateExamPatch$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch$Plain(params?: ApiQyasExamUpdateExamPatch$Plain$Params, context?: HttpContext): Observable<UpdateTExamCommandResult> {
    return this.apiQyasExamUpdateExamPatch$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamCommandResult>): UpdateTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamUpdateExamPatch()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch$Response(params?: ApiQyasExamUpdateExamPatch$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateTExamCommandResult>> {
    return apiQyasExamUpdateExamPatch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamUpdateExamPatch$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiQyasExamUpdateExamPatch(params?: ApiQyasExamUpdateExamPatch$Params, context?: HttpContext): Observable<UpdateTExamCommandResult> {
    return this.apiQyasExamUpdateExamPatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateTExamCommandResult>): UpdateTExamCommandResult => r.body)
    );
  }

  /** Path part for operation `apiQyasExamDeleteExamDelete()` */
  static readonly ApiQyasExamDeleteExamDeletePath = '/api/QyasExam/DeleteExam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamDeleteExamDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete$Plain$Response(params?: ApiQyasExamDeleteExamDelete$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamCommandResult>> {
    return apiQyasExamDeleteExamDelete$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamDeleteExamDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete$Plain(params?: ApiQyasExamDeleteExamDelete$Plain$Params, context?: HttpContext): Observable<DeleteTExamCommandResult> {
    return this.apiQyasExamDeleteExamDelete$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamCommandResult>): DeleteTExamCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiQyasExamDeleteExamDelete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete$Response(params?: ApiQyasExamDeleteExamDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<DeleteTExamCommandResult>> {
    return apiQyasExamDeleteExamDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiQyasExamDeleteExamDelete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiQyasExamDeleteExamDelete(params?: ApiQyasExamDeleteExamDelete$Params, context?: HttpContext): Observable<DeleteTExamCommandResult> {
    return this.apiQyasExamDeleteExamDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteTExamCommandResult>): DeleteTExamCommandResult => r.body)
    );
  }

}
