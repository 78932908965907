/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AnswerTExamDto } from '../../models/answer-t-exam-dto';
import { CreateTExamQuestionCommandResult } from '../../models/create-t-exam-question-command-result';
import { QuestionTExamDto } from '../../models/question-t-exam-dto';
import { TExamQuestionType } from '../../models/t-exam-question-type';

export interface ApiTExamCreateTExamQuestionPost$Params {
      body?: {
'Question.AudioFile'?: Blob;
'Question.AudioPlayTimes'?: number;
'Question.Type'?: TExamQuestionType;
'Question.Sort'?: number;
'Question.Title'?: string;
'Question.QuestionImage'?: Blob;
'Question.TExamId'?: number;
'Question.Answers'?: Array<AnswerTExamDto>;
'SubQuestions'?: Array<QuestionTExamDto>;
}
}

export function apiTExamCreateTExamQuestionPost(http: HttpClient, rootUrl: string, params?: ApiTExamCreateTExamQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CreateTExamQuestionCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiTExamCreateTExamQuestionPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CreateTExamQuestionCommandResult>;
    })
  );
}

apiTExamCreateTExamQuestionPost.PATH = '/api/TExam/CreateTExamQuestion';
