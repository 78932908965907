<div *ngIf="gettingData" class="d-flex justify-content-center py-5 align-items-center">
    <div class="spinner-border text-primary spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>



<div *ngIf="!gettingData && (!allStudents || !allStudents?.length)" class="py-5 text-center">
    <p class="text-muted mb-0">
        لا يوجد متميزين
    </p>
</div>

<ng-container *ngIf="allStudents?.length">
    <section class="py-5 wrapper talents h-100">
        <div class="overlay"></div>
        <div class="container">
            <h4 class="mb-100px text-center">
                <span class="underline">

                    <span class="text-warning">
                        فخر
                    </span>
                    العرب المتميز (ما شاء الله تبارك الله)


                </span>
            </h4>
            <div class="d-grid">
                <owl-carousel-o class="container" [options]="coursesCarousel">

                    <ng-container *ngFor="let item of allStudents">
                        <ng-template carouselSlide [id]="item?.id + ''">
                            <div class="card mb-0 border border-warning rounded-3 h-100">
                                <div class="card-body">
                                    <div class="w-100 h-100 d-flex position-relative flex-wrap justify-content-center  gap-2">
                                        <img src="/assets/images/icon/crown.svg" class="crown" alt="">
                                        <div class="ts_img align-self-start">
                                            <img class="" #img nz-image
                                                (error)="img.src = (item.gender == genderEnum.Female ? '/assets/images/users/girl.png':'/assets/images/users/boy.png')"
                                                [nzSrc]="item.imageUrl ? domain + item.imageUrl :
                                     (item.gender == genderEnum.Female ? '/assets/images/users/girl.png':'/assets/images/users/boy.png')"
                                                alt="">
                                        </div>
                                        <div class="text w-100 align-self-start">
                                            <h5 class="mb-3 underline text-center">
                                                {{item.name}}
                                            </h5>
                                            <p style="white-space: pre-line;" class="small mt-2 text-center">
                                                {{item?.description}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>

                </owl-carousel-o>
            </div>

        </div>

    </section>
</ng-container>