/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiSuperAdminActionsCloseAcademicSemesterPost } from '../fn/super-admin-actions/api-super-admin-actions-close-academic-semester-post';
import { ApiSuperAdminActionsCloseAcademicSemesterPost$Params } from '../fn/super-admin-actions/api-super-admin-actions-close-academic-semester-post';
import { apiSuperAdminActionsCloseAcademicSemesterPost$Plain } from '../fn/super-admin-actions/api-super-admin-actions-close-academic-semester-post-plain';
import { ApiSuperAdminActionsCloseAcademicSemesterPost$Plain$Params } from '../fn/super-admin-actions/api-super-admin-actions-close-academic-semester-post-plain';
import { apiSuperAdminActionsSubmitWeeklyReportPost } from '../fn/super-admin-actions/api-super-admin-actions-submit-weekly-report-post';
import { ApiSuperAdminActionsSubmitWeeklyReportPost$Params } from '../fn/super-admin-actions/api-super-admin-actions-submit-weekly-report-post';
import { apiSuperAdminActionsSubmitWeeklyReportPost$Plain } from '../fn/super-admin-actions/api-super-admin-actions-submit-weekly-report-post-plain';
import { ApiSuperAdminActionsSubmitWeeklyReportPost$Plain$Params } from '../fn/super-admin-actions/api-super-admin-actions-submit-weekly-report-post-plain';
import { CloseAcademicSemesterCommandResult } from '../models/close-academic-semester-command-result';
import { SubmitWeeklyReportCommandResult } from '../models/submit-weekly-report-command-result';

@Injectable({ providedIn: 'root' })
export class SuperAdminActionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSuperAdminActionsCloseAcademicSemesterPost()` */
  static readonly ApiSuperAdminActionsCloseAcademicSemesterPostPath = '/api/SuperAdminActions/CloseAcademicSemester';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSuperAdminActionsCloseAcademicSemesterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsCloseAcademicSemesterPost$Plain$Response(params?: ApiSuperAdminActionsCloseAcademicSemesterPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CloseAcademicSemesterCommandResult>> {
    return apiSuperAdminActionsCloseAcademicSemesterPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSuperAdminActionsCloseAcademicSemesterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsCloseAcademicSemesterPost$Plain(params?: ApiSuperAdminActionsCloseAcademicSemesterPost$Plain$Params, context?: HttpContext): Observable<CloseAcademicSemesterCommandResult> {
    return this.apiSuperAdminActionsCloseAcademicSemesterPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CloseAcademicSemesterCommandResult>): CloseAcademicSemesterCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSuperAdminActionsCloseAcademicSemesterPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsCloseAcademicSemesterPost$Response(params?: ApiSuperAdminActionsCloseAcademicSemesterPost$Params, context?: HttpContext): Observable<StrictHttpResponse<CloseAcademicSemesterCommandResult>> {
    return apiSuperAdminActionsCloseAcademicSemesterPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSuperAdminActionsCloseAcademicSemesterPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsCloseAcademicSemesterPost(params?: ApiSuperAdminActionsCloseAcademicSemesterPost$Params, context?: HttpContext): Observable<CloseAcademicSemesterCommandResult> {
    return this.apiSuperAdminActionsCloseAcademicSemesterPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<CloseAcademicSemesterCommandResult>): CloseAcademicSemesterCommandResult => r.body)
    );
  }

  /** Path part for operation `apiSuperAdminActionsSubmitWeeklyReportPost()` */
  static readonly ApiSuperAdminActionsSubmitWeeklyReportPostPath = '/api/SuperAdminActions/SubmitWeeklyReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSuperAdminActionsSubmitWeeklyReportPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsSubmitWeeklyReportPost$Plain$Response(params?: ApiSuperAdminActionsSubmitWeeklyReportPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitWeeklyReportCommandResult>> {
    return apiSuperAdminActionsSubmitWeeklyReportPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSuperAdminActionsSubmitWeeklyReportPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsSubmitWeeklyReportPost$Plain(params?: ApiSuperAdminActionsSubmitWeeklyReportPost$Plain$Params, context?: HttpContext): Observable<SubmitWeeklyReportCommandResult> {
    return this.apiSuperAdminActionsSubmitWeeklyReportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitWeeklyReportCommandResult>): SubmitWeeklyReportCommandResult => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSuperAdminActionsSubmitWeeklyReportPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsSubmitWeeklyReportPost$Response(params?: ApiSuperAdminActionsSubmitWeeklyReportPost$Params, context?: HttpContext): Observable<StrictHttpResponse<SubmitWeeklyReportCommandResult>> {
    return apiSuperAdminActionsSubmitWeeklyReportPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiSuperAdminActionsSubmitWeeklyReportPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSuperAdminActionsSubmitWeeklyReportPost(params?: ApiSuperAdminActionsSubmitWeeklyReportPost$Params, context?: HttpContext): Observable<SubmitWeeklyReportCommandResult> {
    return this.apiSuperAdminActionsSubmitWeeklyReportPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubmitWeeklyReportCommandResult>): SubmitWeeklyReportCommandResult => r.body)
    );
  }

}
